//
// Usefull mixins.
//
// Some copied from LESS Prefixer
//

.auto-clear()
{
    &:after
    {
        content: " ";
        display: table;
        clear: both;
    }
}

.overflow-ellipsis()
{
    display: block;
    -o-text-overflow: ellipsis; /* Opera */
    text-overflow: ellipsis; /* IE, Safari (WebKit) */
    overflow: hidden; /* don't show excess chars */
    white-space: nowrap; /* force single line */
}

.top-left()
{
    position: absolute;
    top: 0px;
    right: auto;
    bottom: auto;
    left: 0px;
}

.top-right()
{
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: auto;
    left: auto;
}

.bottom-left()
{
    position: absolute;
    top: auto;
    right: auto;
    bottom: 0px;
    left: 0px;
}

.bottom-right()
{
    position: absolute;
    top: auto;
    right: 0px;
    bottom: 0px;
    left: auto;
}

.full-size()
{
    width: 100%;
    height: 100%;
}

.hide-text()
{
    text-transform: capitalize;
    text-indent: -9999px;
    white-space: nowrap;
    overflow: hidden;
}

.reset-basic()
{
    margin: 0;
    padding: 0;
    border: none;
    list-style: none;
    text-decoration: none;
}

.block()
{
    display: block;
}

.centered-box()
{
    display: -webkit-box;
    display: -moz-box;
    -webkit-box-pack: center;
    -webkit-box-align: center;
    -moz-box-pack: center;
    -moz-box-align: center;
}

.size (@width: auto, @height: auto)
{
    width: @width;
    height: @height;
}

.square (@size: 100px)
{
    width: @size;
    height: @size;
}

.position (@x: 0px, @y: 0px)
{
    position: absolute;
    top: @y;
    left: @x;
    right: auto;
    bottom: auto;
}

.position-top-right(@x: 0px, @y: 0px)
{
    position: absolute;
    top: @y;
    right: @x;
    bottom: auto;
    left: auto;
}

.position-bottom-left(@x: 0px, @y: 0px)
{
    position: absolute;
    top: auto;
    right: auto;
    bottom: @y;
    left: @x;
}

.position-bottom-right(@x: 0px, @y: 0px)
{
    position: absolute;
    top: auto;
    right: @x;
    bottom: @y;
    left: auto;
}

// Animation

.animation(@args) {
    -webkit-animation: @args;
    -moz-animation: @args;
    -ms-animation: @args;
    -o-animation: @args;
    animation: @args;
}
.animation-delay(@delay) {
    -webkit-animation-delay: @delay;
    -moz-animation-delay: @delay;
    -ms-animation-delay: @delay;
    -o-animation-delay: @delay;
    animation-delay: @delay;
}
.animation-direction(@direction) {
    -webkit-animation-direction: @direction;
    -moz-animation-direction: @direction;
    -ms-animation-direction: @direction;
    -o-animation-direction: @direction;
}
.animation-duration(@duration) {
    -webkit-animation-duration: @duration;
    -moz-animation-duration: @duration;
    -ms-animation-duration: @duration;
    -o-animation-duration: @duration;
}
.animation-fill-mode(@mode) {
    -webkit-animation-fill-mode: @mode;
    -moz-animation-fill-mode: @mode;
    -ms-animation-fill-mode: @mode;
    -o-animation-fill-mode: @mode;
    animation-fill-mode: @mode;
}
.animation-iteration-count(@count) {
    -webkit-animation-iteration-count: @count;
    -moz-animation-iteration-count: @count;
    -ms-animation-iteration-count: @count;
    -o-animation-iteration-count: @count;
    animation-iteration-count: @count;
}
.animation-name(@name) {
    -webkit-animation-name: @name;
    -moz-animation-name: @name;
    -ms-animation-name: @name;
    -o-animation-name: @name;
    animation-name: @name;
}
.animation-play-state(@state) {
    -webkit-animation-play-state: @state;
    -moz-animation-play-state: @state;
    -ms-animation-play-state: @state;
    -o-animation-play-state: @state;
    animation-play-state: @state;
}
.animation-timing-function(@function) {
    -webkit-animation-timing-function: @function;
    -moz-animation-timing-function: @function;
    -ms-animation-timing-function: @function;
    -o-animation-timing-function: @function;
    animation-timing-function: @function;
}


// Background Size

.background-size(@args) {
    -webkit-background-size: @args;
    background-size: @args;
}

// Border Radius

.border-radius (@value: 5px)
{
    -webkit-border-radius: @value;
    -moz-border-radius: @value;
    border-radius: @value;
    background-clip: padding-box;
    behavior: url(/Content/Css/vendor/PIE.htc);
}

// Box Shadows

.box-shadow(@args) {
    -webkit-box-shadow: @args;
    box-shadow: @args;
}
.inner-shadow(@args) {
    .box-shadow(inset @args);
}

// Box Sizing

.box-sizing(@args) {
    -webkit-box-sizing: @args;
    -moz-box-sizing: @args;
    box-sizing: @args;
}
.border-box(){
    .box-sizing(border-box);
}
.content-box(){
    .box-sizing(content-box);
}

.drop-shadow (@value: 0 1px 3px 0 #999)
{
    -moz-box-shadow: @value;
    -webkit-box-shadow: @value;
    box-shadow: @value;
    behavior: url(/Content/Css/vendor/PIE.htc);
}
// Gradients

.gradient(@default: #F5F5F5, @start: #EEE, @stop: #FFF) {
    .linear-gradient-top(@default,@start,0%,@stop,100%);
}
.linear-gradient-top(@default,@color1,@stop1,@color2,@stop2) {
    background-color: @default;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(@stop1, @color1), color-stop(@stop2 @color2));
    background-image: -webkit-linear-gradient(top, @color1 @stop1, @color2 @stop2);
    background-image: -moz-linear-gradient(top, @color1 @stop1, @color2 @stop2);
    background-image: -ms-linear-gradient(top, @color1 @stop1, @color2 @stop2);
    background-image: -o-linear-gradient(top, @color1 @stop1, @color2 @stop2);
    background-image: linear-gradient(top, @color1 @stop1, @color2 @stop2);
}
.linear-gradient-top(@default,@color1,@stop1,@color2,@stop2,@color3,@stop3) {
    background-color: @default;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(@stop1, @color1), color-stop(@stop2 @color2), color-stop(@stop3 @color3));
    background-image: -webkit-linear-gradient(top, @color1 @stop1, @color2 @stop2, @color3 @stop3);
    background-image: -moz-linear-gradient(top, @color1 @stop1, @color2 @stop2, @color3 @stop3);
    background-image: -ms-linear-gradient(top, @color1 @stop1, @color2 @stop2, @color3 @stop3);
    background-image: -o-linear-gradient(top, @color1 @stop1, @color2 @stop2, @color3 @stop3);
    background-image: linear-gradient(top, @color1 @stop1, @color2 @stop2, @color3 @stop3);
}
.linear-gradient-top(@default,@color1,@stop1,@color2,@stop2,@color3,@stop3,@color4,@stop4) {
    background-color: @default;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(@stop1, @color1), color-stop(@stop2 @color2), color-stop(@stop3 @color3), color-stop(@stop4 @color4));
    background-image: -webkit-linear-gradient(top, @color1 @stop1, @color2 @stop2, @color3 @stop3, @color4 @stop4);
    background-image: -moz-linear-gradient(top, @color1 @stop1, @color2 @stop2, @color3 @stop3, @color4 @stop4);
    background-image: -ms-linear-gradient(top, @color1 @stop1, @color2 @stop2, @color3 @stop3, @color4 @stop4);
    background-image: -o-linear-gradient(top, @color1 @stop1, @color2 @stop2, @color3 @stop3, @color4 @stop4);
    background-image: linear-gradient(top, @color1 @stop1, @color2 @stop2, @color3 @stop3, @color4 @stop4);
}
.linear-gradient-left(@default,@color1,@stop1,@color2,@stop2) {
    background-color: @default;
    background-image: -webkit-gradient(linear, left top, left top, color-stop(@stop1, @color1), color-stop(@stop2 @color2));
    background-image: -webkit-linear-gradient(left, @color1 @stop1, @color2 @stop2);
    background-image: -moz-linear-gradient(left, @color1 @stop1, @color2 @stop2);
    background-image: -ms-linear-gradient(left, @color1 @stop1, @color2 @stop2);
    background-image: -o-linear-gradient(left, @color1 @stop1, @color2 @stop2);
    background-image: linear-gradient(left, @color1 @stop1, @color2 @stop2);
}
.linear-gradient-left(@default,@color1,@stop1,@color2,@stop2,@color3,@stop3) {
    background-color: @default;
    background-image: -webkit-gradient(linear, left top, left top, color-stop(@stop1, @color1), color-stop(@stop2 @color2), color-stop(@stop3 @color3));
    background-image: -webkit-linear-gradient(left, @color1 @stop1, @color2 @stop2, @color3 @stop3);
    background-image: -moz-linear-gradient(left, @color1 @stop1, @color2 @stop2, @color3 @stop3);
    background-image: -ms-linear-gradient(left, @color1 @stop1, @color2 @stop2, @color3 @stop3);
    background-image: -o-linear-gradient(left, @color1 @stop1, @color2 @stop2, @color3 @stop3);
    background-image: linear-gradient(left, @color1 @stop1, @color2 @stop2, @color3 @stop3);
}
.linear-gradient-left(@default,@color1,@stop1,@color2,@stop2,@color3,@stop3,@color4,@stop4) {
    background-color: @default;
    background-image: -webkit-gradient(linear, left top, left top, color-stop(@stop1, @color1), color-stop(@stop2 @color2), color-stop(@stop3 @color3), color-stop(@stop4 @color4));
    background-image: -webkit-linear-gradient(left, @color1 @stop1, @color2 @stop2, @color3 @stop3, @color4 @stop4);
    background-image: -moz-linear-gradient(left, @color1 @stop1, @color2 @stop2, @color3 @stop3, @color4 @stop4);
    background-image: -ms-linear-gradient(left, @color1 @stop1, @color2 @stop2, @color3 @stop3, @color4 @stop4);
    background-image: -o-linear-gradient(left, @color1 @stop1, @color2 @stop2, @color3 @stop3, @color4 @stop4);
    background-image: linear-gradient(left, @color1 @stop1, @color2 @stop2, @color3 @stop3, @color4 @stop4);
}


// Opacity

.opacity(@factor) {
    @iefactor: @factor*100;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=@{iefactor})";
	filter: ~"alpha(opacity=(@{iefactor}))";
    opacity: @factor;
}


// Text Shadow

.text-shadow(@args) {
    text-shadow: @args;
}


// Transforms

.transform(@args) {
    -webkit-transform: @args;
    -moz-transform: @args;
    -ms-transform: @args; 
    -o-transform: @args;
    transform: @args;
}
.transform-origin(@args) {
    -webkit-transform-origin: @args;
    -moz-transform-origin: @args;
    -ms-transform-origin: @args; 
    -o-transform-origin: @args;
    transform-origin: @args;
}
.transform-style(@style) {
    -webkit-transform-style: @style;
    -moz-transform-style: @style;
    -ms-transform-style: @style; 
    -o-transform-style: @style;
    transform-style: @style;
}
.rotate(@deg:45deg){
    .transform(rotate(@deg));
}
.scale(@factor:.5){
    .transform(scale(@factor));
}
.translate(@x,@y){
    .transform(translate(@x,@y));
}
.translate3d(@x,@y,@z) {
    .transform(translate3d(@x,@y,@z));
}
.translateHardware(@x,@y) {
    .translate(@x,@y);
    -webkit-transform: translate3d(@x,@y,0);
    -moz-transform: translate3d(@x,@y,0);
    -o-transform: translate3d(@x,@y,0);
    -ms-transform: translate3d(@x,@y,0);
    transform: translate3d(@x,@y,0);
}

// Transitions

.transition(@args:200ms) {
    -webkit-transition: @args;
    -moz-transition: @args;
    -o-transition: @args;
    -ms-transition: @args;
    transition: @args;
}
.transition-delay(@delay:0) {
    -webkit-transition-delay: @delay;
    -moz-transition-delay: @delay;
    -o-transition-delay: @delay;
    -ms-transition-delay: @delay;
    transition-delay: @delay;
}
.transition-duration(@duration:200ms) {
    -webkit-transition-duration: @duration;
    -moz-transition-duration: @duration;
    -o-transition-duration: @duration;
    -ms-transition-duration: @duration;
    transition-duration: @duration;
}
.transition-property(@property:all) {
    -webkit-transition-property: @property;
    -moz-transition-property: @property;
    -o-transition-property: @property;
    -ms-transition-property: @property;
    transition-property: @property;
}
.transition-timing-function(@function:ease) {
    -webkit-transition-timing-function: @function;
    -moz-transition-timing-function: @function;
    -o-transition-timing-function: @function;
    -ms-transition-timing-function: @function;
    transition-timing-function: @function;
}

.transition-property-transform()
{
    -moz-transition-property: -moz-transform;
    -o-transition-property: -o-transform;
    -webkit-transition-property: -webkit-transform;
    -ms-transition-property: -ms-transform;
    transition-property: transform;
}

.transition-property-transform(@other)
{
    -moz-transition-property: -moz-transform, @other;
    -o-transition-property: -o-transform, @other;
    -webkit-transition-property: -webkit-transform, @other;
    -ms-transition-property: -ms-transform, @other;
    transition-property: transform, @other;
}

.transform-transition(@time-ease)
{
    -moz-transition: -moz-transform @time-ease;
    -o-transition: -o-transform @time-ease;
    -webkit-transition: -webkit-transform @time-ease;
    -ms-transition: -ms-transform @time-ease;
    transition: transform @time-ease;
}

.arrow-top(@color, @size)
{
    &:after
    {
        top: auto;
        right: auto;
        bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: transparent;
        border-bottom-color: @color;
        border-width: @size;
        margin-left: -@size;
    }
}

.arrow-right(@color, @size)
{
    &:after
    {
        top: 50%;
        right: auto;
        bottom: auto;
        left: 100%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: transparent;
        border-left-color: @color;
        border-width: @size;
        margin-top: -@size;
    }
}

.arrow-bottom(@color, @size)
{
    &:after
    {
        top: 100%;
        right: auto;
        bottom: auto;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: transparent;
        border-top-color: @color;
        border-width: @size;
        margin-left: -@size;
    }
}

.arrow-left(@color, @size)
{
    &:after
    {
        top: 50%;
        right: 100%;
        bottom: auto;
        left: auto;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-right-color: @color;
        border-width: @size;
        margin-top: -@size;
    }
}
