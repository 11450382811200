@font-face {
  font-family: 'Francois One';
  font-style: normal;
  font-weight: 400;
  src:  local('Francois One Regular'), 
        local('FrancoisOne-Regular'), 
        url('/content/fonts/francoisone.woff') format('woff');
}

@font-face {
  font-family: 'PT Sans Narrow';
  font-style: normal;
  font-weight: 400;
  src:  local('PT Sans Narrow'), 
        local('PTSans-Narrow'), 
        url('/content/fonts/ptsansnarrow.woff') format('woff');
}


// color adjustments
@color-increment: 10%;
@color-primary-lighter:     lighten(#43586d, @color-increment);
@color-primary:                     #43586d;
@color-primary-darker:       darken(#43586d, @color-increment);
@color-secondary-lighter:   lighten(#0998dc, @color-increment);
@color-secondary:                   #0998dc;
@color-secondary-darker:     darken(#0998dc, @color-increment);
@color-tertiary-lighter:    lighten(#95cbef, @color-increment);
@color-tertiary:                    #95cbef;
@color-tertiary-darker:      darken(#95cbef, @color-increment);
@color-highlight-lighter:   lighten(#e15626, @color-increment);
@color-highlight:                   #e15626;
@color-highlight-darker:     darken(#e15626, @color-increment);

@color-gray-darker: #333;
@color-gray-dark: #777;
@color-gray: #aaa;
@color-gray-light: #ecf0f1;
@color-gray-lighter: #f4f4f4;

@color-background: #fff;
@color-background-alternate: @color-gray-light;

@color-text: @color-primary;
@color-text-highlight: @color-secondary;
@color-link: @color-secondary;
@color-link-hover: @color-tertiary;
@color-link-active: @color-highlight;