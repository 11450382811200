﻿@import "lib/lib.less";
@import "variables.less";

//
// COURSES LIST
//

.courses-list {
    padding: 0;

    &-course {
        position: relative;
        display: block;
        width: 100%;
        margin: 0;
        padding: 8px 0;

        font-size: 2rem;
        font-weight: bold;

        a {
            position: absolute;
            right: 0;
            top: 12px;
            display: inline-block;
            font-size: 1.6rem;
            vertical-align: middle;
            text-transform:none;
        }

        &:before {
            display: none;
        }
    }
}

@media (min-width: 1300px) {
    .courses-list {
        &-course {
            font-size: 2.4rem;
        }
    }
}

//
// SUBMODULES LIST
//

.submodules-list {
    .reset-basic();

    font-size: 1.4em;
    font-weight: bold;

    &-course {
        margin: 0;
        padding: 12px 0;
        border-bottom: 2px solid @color-secondary;
        text-transform:uppercase;

        i {
            font-style: normal;
            color: @color-secondary;
        }

        span {
            position: absolute;
            left: 200px;
            font-weight: normal;
            text-transform:none;
            font-size: 1.5rem;
        }

        a {
            vertical-align: middle;
            text-transform:none;
            line-height: normal;
        }

        &-submodule {
            display: block;
            width: 100%;
            height: 51px;
            line-height: 51px;
            position: absolute;
            top: 0;

            &:nth-child(2) {
                top: 51px;
            }
        }

        &--hasSubmodules {
            height: 102px;
        }

        &:before {
            display: none;
        }
    }
}

@media (min-width: 1300px) {
    .submodules-list {
        &-course {
            span {
                font-size: 1.8rem;
            }
        }
    }
}

//
// SUBMODULES LIST
//

.references-list {
    .reset-basic();

    font-size: 1.4em;
    font-weight: bold;

    &-course {
        margin: 0;
        padding: 6px 0;
        border-bottom: 2px solid @color-secondary;
        text-transform:uppercase;

        i {
            font-style: normal;
            color: @color-secondary;
        }

        span {
            position: absolute;
            right: 0;
            left: auto;
            font-weight: normal;
            text-transform:none;
        }

        &:before {
            display: none;
        }
    }
}

//
// SLIDES
//

.slides {
    position: relative;
    width: 100%;
    height: 480px;

    &-navigation {
        .square(60px);
        .block();
        position: fixed;
        top: 50%;
        left: 30px;
        z-index: 55;
        margin-top: -30px;
        background: no-repeat center center;
        cursor: pointer;
        .transition(all .3s ease-in-out);

        &--previous {
            background-image: url('../images/icon-previous-grey-60x60.png');
        }

        &--next {
            background-image: url('../images/icon-next-grey-60x60.png');
            right: 30px;
            left: auto;
        }

        &--is-hidden {
            opacity: 0;
            pointer-events: none;
        }

        &:hover {
            &.slides-navigation {
                &--previous {
                    background-image: url('../images/icon-previous-orange-60x60.png');
                }

                &--next {
                    background-image: url('../images/icon-next-orange-60x60.png');
                }
            }
        }
    }

    &-overview {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: block;
        background: @color-background;
        opacity: 0;
        pointer-events: none;
        .transition(opacity .3s ease-in-out);

        &-slide {
            position: relative;
            width: 12.5%;
            display: block;
            float: left;
            overflow: hidden;
            padding: 10px 10px 5px;
            box-sizing: border-box;
            cursor: pointer;

            img {
                width: 100%;
                margin: 0 auto;
            }
        }

        &--is-active {
            opacity: 1;
            pointer-events: all;
        }
    }
}

.slide {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    opacity: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    pointer-events: none;
    .transform(translate3D(0,0,0));
    .transition(all .3s ease-in-out);

    &-image {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        display: inline-block;
        
        .transition(all .3s ease-in-out);

        img {
            display: inline-block;
            height: 100%;
            margin: 0 auto;
        }
    }

    &-caption {
        position: absolute;
        bottom: -25px;
        left: 10%;
    }

    &-text {
        opacity: 0;
        position: absolute;
        bottom: 0;
        left:25%;
        width:50%;
        max-height: 40%;
        height: 1%;
        font-size: 1.6rem;
        overflow-y: scroll;
        text-align: left; 

        ul li {
            font-size: 1.6rem;
        }
        
        .transition(all .3s ease-in-out);
    }

    &-fullscreen {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -50;
        opacity: 0;
        text-align: center;
        background: @color-gray-light;
        cursor: pointer;

        .transition(opacity .3s ease-in-out);

        img {
            display: inline-block;
            height: 100%;
        }

        &--visible {
            z-index: 50;
            opacity: 1;
        }

        &-close {
            width: 60px;
            height: 60px;
            display: block;
            position: absolute;
            bottom: 35px;
            right: 100px;
            background: url('/content/images/icon-shrink-grey-60x60.png') no-repeat center center;
            .transition(.3s ease-in-out);

            &:hover {
                background: url('/content/images/icon-shrink-orange-60x60.png') no-repeat center center;
            }
        }
    }

    &.end {
        h3 {
            padding: 30px 50px 50px;
        }
    }

    &--active {
        display: block;
        opacity: 1;
        pointer-events: all;
        .transform(translate3D(0,0,0));
    }

    &--previous {
        display: block;
        opacity: 0;
        .transform(translate3D(0,0,0));
    }

    &--text-active {
        .slide {
            &-image {
                height: 50%;
                width: 50%;
            }

            &-text {
                opacity: 1;
                height: 40%;
            }
        }
    }
}

.fullscreen-counter {
    position: absolute;
    bottom: 50px;
    right: 50px;
    font-family: 'Francois One', sans-serif; 
    font-size: 2rem;
    font-weight: bold;

    span {
        color: @color-highlight;
    }
}

@media (min-width: 1300px) {
    .slides {
        height: 540px;
    }
}

@media (min-width: 1920px) {
    .slides {
        height: 640px;
    }
}

//
// COURSE NAVIGATION
//

.course-navigation {
    position: relative;
    display: block;
    width: 100%;
    text-align: center;
    padding: 30px 0;
    line-height: 0px;
}

.course-navigation-buttons {
    .reset-basic;
    display: inline-block;
    height: 80px;
    width: 476px;
    padding: 10px 0;
    border: 1px solid @color-gray-lighter;
    text-align: left;
    .border-radius(2px);
}

.course-navigation-button {
    .reset-basic();
    float: left;
    width: 79px;
    height: 60px;
    border-right: 1px solid @color-gray-light;
    text-align: center;
    line-height: 60px;
    font-size: 3em;
    font-weight: bold;
    font-family: 'Francois One', sans-serif; 
    cursor: pointer;
    background-size: 100% 100%;
    .transition(all .3s ease-in-out);

    &:last-child {
        border-right: none;
    }

    &:before {
        display: none;
    }

    &-current {
        color: @color-highlight;
    }

    &-enlarge {
        background: url('../images/icon-enlarge-grey-60x60.png') no-repeat center center;
    }

    &-video {
        background: url('../images/icon-video-grey-60x60.png') no-repeat center center;
    }

    &-text {
        background: url('../images/icon-text-grey-60x60.png') no-repeat center center;
    }

    &-sound {   
        background: url('../images/icon-soundon-grey-60x60.png') no-repeat center center;
    }

    &-sound-off {   
        background: url('../images/icon-soundoff-grey-60x60.png') no-repeat center center;
    }

    &-overview {
        background: url('../images/icon-overview-grey-60x60.png') no-repeat center center;
    }

    &--is-disabled {
        opacity: 0.4;
    }

    &:hover, &--is-active {
        &.course-navigation-button {

            &-enlarge {
                background: url('../images/icon-enlarge-orange-60x60.png') no-repeat center center;
            }

            &-video {
                background: url('../images/icon-video-orange-60x60.png') no-repeat center center;
            }

            &-text {
                background: url('../images/icon-text-orange-60x60.png') no-repeat center center;
            }

            &-sound {   
                background: url('../images/icon-soundon-orange-60x60.png') no-repeat center center;
            }

            &-sound-off {   
                background: url('../images/icon-soundoff-orange-60x60.png') no-repeat center center;
            }

            &-overview {
                background: url('../images/icon-overview-orange-60x60.png') no-repeat center center;
            }

            &--is-disabled {
                &.course-navigation-button {
                    &-enlarge {
                        background: url('../images/icon-enlarge-grey-60x60.png') no-repeat center center;
                    }

                    &-video {
                        background: url('../images/icon-video-grey-60x60.png') no-repeat center center;
                    }

                    &-text {
                        background: url('../images/icon-text-grey-60x60.png') no-repeat center center;
                    }

                    &-sound {   
                        background: url('../images/icon-soundon-grey-60x60.png') no-repeat center center;
                    }

                    &-sound-off {   
                        background: url('../images/icon-soundoff-grey-60x60.png') no-repeat center center;
                    }

                    &-overview {
                        background: url('../images/icon-overview-grey-60x60.png') no-repeat center center;
                    }
                }
            }
        }
    }
}

.course-audio {
    display: block;
    margin: 0 auto;
    height: 3px;
    width: 476px;
    background: #fff;

    &-progress {
        width: 0%;
        height: 3px;
        background: @color-primary;

        .transition(all .3s ease-in-out);
    }
}

//
// TESTS
//

.questions {
    position: relative;
    width: 100%;
    height: 480px;

    &-final {
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -111px !important;
        opacity: 0;
        pointer-events: none;

        .transition('opacity .4s ease-in-out 1.5s');

        &.active {
            opacity: 1;
            pointer-events: all;
        }
    }

    &-navigation {
        .square(60px);
        .block();
        position: fixed;
        top: 50%;
        left: 30px;
        z-index: 55;
        margin-top: -30px;
        background: no-repeat center center;
        cursor: pointer;
        .transition(all .3s ease-in-out);

        &--previous {
            background-image: url('../images/icon-previous-grey-60x60.png');
        }

        &--next {
            background-image: url('../images/icon-next-grey-60x60.png');
            right: 30px;
            left: auto;
        }

        &--is-hidden {
            opacity: 0;
            pointer-events: none;
        }

        &:hover {
            &.slides-navigation {
                &--previous {
                    background-image: url('../images/icon-previous-orange-60x60.png');
                }

                &--next {
                    background-image: url('../images/icon-next-orange-60x60.png');
                }
            }
        }
    }
}

.question {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    opacity: 0;
    width: 100%;
    height: 100%;
    text-align: left;
    pointer-events: none;
    .transform(translate3D(100%,0,0));
    .transition(all .3s ease-in-out);

    &-title {

    }

    &-feedback {
        position: relative;
        z-index: 5;
        opacity: 0;
        pointer-events: none;
        .transition(all .4s ease-in-out 2s);

        &--is-active {
            opacity: 1;
            pointer-events: all;
        }
    }

    &--is-active {
        .transform(translate3D(0,0,0));
        opacity: 1;
        pointer-events: all;
    }

    &--is-answered {
        .transform(translate3D(-100%,0,0));
        opacity: 0;
        pointer-events: none;
    }
}

.answers {
    .reset-basic();
    margin: 30px 0;
    height: auto;
    .transition(all .3s ease-in-out);

    &--is-answered {
        height: 80px;
    }

    .answer {
        .reset-basic();
        position: relative;
        height: 80px;
        width: 100%;
        padding-left: 100px;
        border: 1px solid @color-gray-lighter;
        font-size: 2rem;
        line-height: 80px;
        cursor: pointer;
        .border-radius(2px);
        .transition(~'background .5s ease-in-out, opacity .5s ease-in-out');

        .centered {
            display: inline-block;
            vertical-align: middle;
            line-height: 2.5rem;
            margin-top: -5px;
            pointer-events: none;
        }

        &:before {
            position: absolute;
            display: block;
            content: '';
            left: 0;
            top: 50%;
            margin-top: -30px;
            height: 60px;
            width: 80px;
            border-right: @color-secondary 1px solid;
            font-size: 5rem;
            font-weight: bold;
            font-family: 'Francois One', sans-serif; 
            text-align: center;
            text-transform: uppercase;
            line-height: 60px;
            color: @color-secondary;
            background: url('/content/images/icon-empty-60x60.png') no-repeat center center;
            .transition(all .5s ease-in-out 1s);
        }

        &:nth-child(1) {
            &:before {
                background-image: url('/content/images/answer-A.png');
            }
        }

        &:nth-child(2) {
            &:before {
                background-image: url('/content/images/answer-B.png');
            }
        }

        &:nth-child(3) {
            &:before {
                background-image: url('/content/images/answer-C.png');
            }
        }
        
        &:nth-child(4) {
            &:before {
                background-image: url('/content/images/answer-D.png');
            }
        }
        
        &:nth-child(5) {
            &:before {
                background-image: url('/content/images/answer-E.png');
            }
        }

        &--is-valid {
            &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5) {
                &:before {
                    content: '';
                    background: url('/content/images/icon-right-green-60x60.png') no-repeat center center;
                }
            }
        }

        &--is-invalid {
            &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5) {
                &:before {
                    content: '';
                    background: url('/content/images/icon-wrong-red-60x60.png') no-repeat center center;
                }
            }
        }

        &--is-hidden {
            opacity: 0;
            pointer-events: none;
        }

        &--is-chosen {
            background: @color-tertiary;
        }
    }
}

@media (min-width: 1300px) {
    .answers {
        .answer {
            font-size: 2.1rem;
        }
    }
}

@media (min-width: 19200px) {
    .answers {
        .answer {
            font-size: 2.2rem;
        }
    }
}


//
// BUTTONS
//
.button {
    display: inline-block; 
    position: relative;
    text-decoration: none;
    padding: 0 28px 2px 8px;
    border: 2px solid @color-secondary;
    color: @color-text;
    height: 28px;
    line-height: 23px;
    font-size: 1.6rem;
    .border-radius(4px);
    .transition(all .3s ease-in-out);

    &:after {
        content: '';
        display: block;
        position: absolute;
        right: 6px;
        top: 7px;
        width: 16px;
        height: 11px;
        background: url('/content/images/icon-goto-blue-15x10.png') no-repeat center center;
        .transition(all .3s ease-in-out);
    }

    &:hover {
        border-color: @color-highlight;
        color: @color-highlight;

        &:after {
            background-image: url('/content/images/icon-goto-orange-15x10.png');
        }
    }

    &--large {
        margin: 8px 0;
        line-height: 50px;
        font-size: 2rem;
        padding: 0 40px 2px 20px;
        height: 54px;

        &:after {
            top: 21px;
            right: 12px;
        }
    }

    &--spaced {
        margin: 15px 0 50px;
    }
}

//
// SPECTRE OVERRIDES
// SPECTRE NAVBAR OVERRIDES
//

.navbar .navbar-section {
    padding: 15px 0;
    font-size: 1.6rem;
}

.navbar .navbar-section .btn-link {
    font-size: 1.6rem;
    font-weight: bold;
    text-decoration: none;
    color: @color-primary;

    &:hover {
        color: @color-link;
    }
}

.navbar-brand {
    font-size: 3rem;
    font-weight: bold;
    text-decoration: none;
    color: @color-primary;

    span {
        color: @color-secondary;
    }
}

@media (min-width: 1300px) {
    .navbar .navbar-section {
        font-size: 2rem;
    }

    .navbar .navbar-section .btn-link {
        font-size: 2rem;
    }
}

@media (min-width: 1920px) {

}

//
// SPECTRE CARD OVERRIDES
//

.card {
    position: relative;
    height: 163px;
    overflow: hidden;
    border: none;
    .border-radius(3px);
        text-decoration: none;

    .card-title {
        font-weight: normal;
        font-size: 1.5rem;
        line-height: 2rem;
        text-decoration: none;
    }

    .card-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: right;

        a {
            color: @color-highlight;
            text-decoration: none;
        }
    }

    &-compact {
        text-align: right;
        padding: .3rem 1.5rem 1rem 1.5rem;

        .card-title {
            text-align: left;
            font-weight: normal;
            font-size: 1rem;
            line-height: 1.3rem;
            margin: 0;
            padding-top: 10px;
        }

        a {
            display: block;
            color: @color-highlight;
            text-decoration: none;
        }
    }
}

@media (min-width: 1300px) {
    .card {
        height: 150px;

        .card-title {
            font-size: 2rem;
            line-height: 2.5rem;
        }

        &-compact {
            .card-title {
                font-size: 1.1rem;
                line-height: 1.5rem;
            }

            a { 
                margin-top: -10px;
            }
        }
    }
}

@media (min-width: 1920px) {
    .card {
        height: 175px;

        &-compact {
            .card-title {
                font-size: 1.3rem;
                line-height: 2rem;
            }
        }
    }
}

//
// SPECTRE GRID HELPERS
//

.column {
    .centered {
        margin: 0 auto;
    }
}
